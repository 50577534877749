/*
* This gets compiled to dist/assets/js/theme.js
*/

// import './aos';
// import './aos';
import './navigation';
import './popovers';
// import './prism';
// import mrReadingPosition from './reading-position';
// import mrSmoothScroll from './smooth-scroll';
// import mrSticky from './sticky';
import './svg-injector';
// import mrTypedText from './typed-text';
import mrUtil from './util';
// import './wizard';

(() => {
  if (typeof $ === 'undefined') {
    throw new TypeError('Medium Rare JavaScript requires jQuery. jQuery must be included before theme.js.');
  }
})();

export default mrUtil;
const ClipboardJS = '';
ClipboardJS('.btn-clip');

jQuery('.toast').toast('dispose');
jQuery('.btn-clip').click(() => {
  jQuery('.toast').toast('show');
});
